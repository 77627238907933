<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
const route = useRoute();
const store = useStore();
const isLoading = ref(true);
const interviewData = ref(null);

onMounted(async () => {
  const { interview } = await store.dispatch(
    "InterviewProcess/fetchSingleInterview",
    {
      interview: route.params?.interviewId || 0,
    }  );

  if (interview.id) {
    isLoading.value = false;
    interviewData.value = interview;
  }
});
</script>
<template>
  <template v-if="isLoading">
    <div class="flex flex-col h-full justify-center relative">
      <div>
        <heart-rate fast="true"></heart-rate>
      </div>
    </div>
  </template>
  <template v-else>
    <div
      class="
        mx-auto
        text-center
        sm:w-full
        lg:w-1/2 lg:px-16
        md:px-5
        sm:px-5
        px-5
      "
    >
      <p class="font-medium sm:text-normal lg:text-2xl my-5">
        Welcome to the <u class="capitalize">{{ interviewData.application_status }}</u>.
      </p>
      <p class="font-medium sm:text-normal lg:text-2xl my-5 capitalize">
        Today’s Candidate is
        {{
          interviewData.user.profile.first_name +
          " " +
          interviewData.user.profile.last_name
        }}
      </p>
      <p class="font-medium sm:text-normal lg:text-2xl my-5 sm:px-0 lg:px-24">
        This interview is scheduled to start at {{ interviewData.time }} for a
        duration of {{ interviewData.duration }}
      </p>
      <p class="text-sm">
        Please click the button below to begin. 
      </p>
      <div class="w-full my-10">
        <img
          class="
            bg-gray-200
            mx-auto
            cursor-pointer
            px-4
            py-4
            rounded-full
            h-32
            w-32
          "
          src="@/assets/icons/joining_call.svg"
        />
      </div>
      <div class="flex justify-end my-5">
        <button
          class="bg-gray-500 hover:bg-gray-700 text-white px-4 rounded-full"
          @click="
            $router.push(
              `/re/startNewInterview/${route.params.interviewId}/conduct`
            )
          "
        >
          Next
        </button>
      </div>
    </div>
  </template>
</template>
